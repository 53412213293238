@import '../../styles/colors';
@import '../../styles/z_indexes';
@import '../../styles/shared_variables';
@import '../../styles/placeholders';

.wrapper {
	position: relative;
	// top: 30px;
	margin-top: 10px;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}

.codeEditor {
	border: 1px solid $input-box-border;
	max-width: 240px;
	word-wrap: break-word;
	font-size: $fs-base;
}

.projectNumber {
	// copied from .fileExtension in FileUpload.module.scss
	text-align: left;
	font: normal normal normal 15px/42px Ubuntu;
	letter-spacing: 0px;
	color: #a6a7ad;
	opacity: 1;
	line-height: 1.5;
}
