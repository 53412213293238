@import '../../styles/colors';
@import '../../styles/media';

.projectListContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0 40px;

	@include mobile-down {
		padding: 0 5px;
	}
}

.buttonWrapper {
	margin: 50px 0;

	@include mobile-down {
		margin: 25px auto;
	}
}

.title {
	color: $text-color;
}

.list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.firstBeraterBadge {
	width: 490px;
	margin-top: 35px;
	margin-left: 90px;
}
