@import '../../styles/z_indexes';
@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/fonts';

.title {
	color: $text-color;
	font-size: 27px;
	max-width: 200px;
	height: 31px;
	line-height: 30px;
}

.icon {
	cursor: pointer;
	margin-left: 15px;
	margin-bottom: 1px;
	path {
		@extend %global-transition;
	}

	&:hover,
	&:active,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}

.titleInput {
	border: none;
	outline: none;
	background-color: transparent;
	color: $text-color;
	font-size: 27px;
	max-width: 200px;
	@extend %secondary-font;
	padding: 0px;
}

.inputGroup {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.zIndex {
	z-index: $z-navbar;
}

.label {
	font-size: 12px;
	margin-bottom: 10px;
	color: $text-color;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}

.warning {
	font-size: 14px;
	text-align: center;
}
