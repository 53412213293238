@import '../../styles/colors';
@import '../../styles/placeholders';

.title {
	color: $text-color;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 27px;
}

.inputGroup {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.label {
	font-size: 12px;
	margin-bottom: 10px;
	color: $text-color;
}

.hiddenFieldsRow {
	align-items: center;

	&:not(:first-of-type) {
		margin-top: 5px;
	}
}

.minusIcon {
	cursor: pointer;
	margin-left: 5px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($primary-color, 10%);
		}
	}
}

.addFieldInputGroup {
	padding: 10px 0px;
	display: flex;
	align-items: center;
}

.addFieldLabel {
	font-size: 12px;
	color: $text-color;
}

.plusIcon {
	cursor: pointer;
	margin-left: 19px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($primary-color, 10%);
		}
	}
}

.addFieldError {
	font-size: 12px;
	color: $error-color;
}

.addFieldErrorOK {
	margin-left: 5px;
	font-size: 12px;
	color: $primary-color;
	text-decoration: underline;
	cursor: pointer;
}

.inputToggle {
	margin-bottom: 10px;
	display: flex;
	position: relative;
	align-items: center;

	label {
		margin-bottom: 0px;
	}
}

.toggleLabels {
	color: var(--unnamed-color-a6a7ad);
	text-align: left;
	font: normal normal normal 15px/42px Ubuntu;
	letter-spacing: 0px;
	color: #a6a7ad;
	opacity: 1;
}
