@import '../../styles/colors';
@import '../../styles/z_indexes';
@import '../../styles/shared_variables';
@import '../../styles/placeholders';

.sidebar {
	flex: 0 $global-sidebar-width-desktop;
	background-color: $light-background-color;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.039));
	-webkit-filter: none;
	height: calc(100vh - #{$global-navbar-height-desktop});
}

.tabs {
	display: flex;
}

.tab {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	font-size: 16px;
	text-transform: uppercase;
	background-color: $background-color;
	color: #9b9b9b;
	cursor: pointer;
}

.active {
	background-color: $light-background-color;
	color: black;
}

.container {
	padding: 20px 0px;
	height: calc(100vh - #{$global-navbar-height-desktop} - 50px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}
