@import '../../styles/colors';
@import '../../styles/placeholders';

.container {
	width: 100%;
	padding: 50px;
}

.title {
	color: $text-color;
	font-size: 30px;
	margin-bottom: 40px;
}

.table {
	color: $text-color;
	width: 100%;
	text-align: left;
	filter: drop-shadow(0px 2px 5px $input-box-border);
	-webkit-filter: none;
	margin-bottom: 30px;
}

.tr {
	border: 1px solid $text-color;
}

.th {
	font-weight: bold;
	font-size: 15px;
	padding: 15px;
	text-align: center;
}

.td {
	// overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 15px;
	padding: 15px;
	text-align: center;
}

.textLeft {
	text-align: left;
}

.link {
	@extend %global-transition;
	color: $primary-color;

	&:hover,
	&:focus,
	&:active,
	&:visited {
		color: $text-color;
	}
}

.searchBar {
	width: 20%;
	min-width: 300px;
	float: right;
	margin-bottom: 10px;

	label {
		font-size: 14px;
	}
}

.inputGroup {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.label {
	font-size: 12px;
	margin-bottom: 10px;
	color: $text-color;
}

.titleInput {
	border: none;
	outline: none;
	width: 100%;
	height: 30px;
}

.xButton {
	background: transparent;
	color: red;
	margin-left: -27px;
	border: none;
	width: 25px;
	height: 25px;
}
