@import '../../styles/colors';
@import '../../styles/placeholders';

.title {
	color: $text-color;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 27px;
}

.inputGroup {
	padding: 7px 0px;
	display: flex;
	align-items: center;
	position: relative;
}

.label {
	font-size: 13px;
	color: $text-color;
	margin-left: 10px;
}

.deleteIcon {
	height: 20px;
	width: 16px;
	margin: -4px 0 0 8px;
	cursor: pointer;
	border-radius: 5px;
	background-color: $light-background-color;
	@extend %global-transition;
	path {
		fill: $grey;
	}
	&:hover,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}
