@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/media.scss';

.container {
	display: flex;
	height: 100vh;
	background-image: url('../../assets/images/LoginGrafik.png');
	background-position: center;
	background-size: cover;
}

.left {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.textWrapper {
	position: relative;
	top: 17%;
}

.title {
	font-size: 60px;
	color: white;
	font-weight: 400;
}

.text {
	font-size: 22px;
	margin: 8% 0%;
	line-height: 26px;
	text-align: center;
	max-width: 60%;
}

.error {
	color: $error-color;
}

.buttonWrapper {
	margin-top: 15%;
}

.logo {
	width: 165px;
	height: 153px;
}

.form {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@include tablet-down {
	.container {
		flex-direction: column;
		background-position: right 25% bottom 15%;
	}

	.left {
		display: none;
	}
}
