@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');

//heading font
%primary-font {
	font-family: 'Ubuntu', sans-serif;
}

//body font
%secondary-font {
	font-family: 'Ubuntu', sans-serif;
}
