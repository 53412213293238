.audioPlayerPosition {
	width: 250px;
	height: 19px;
	display: inline-block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	border: none;
}
// eslint-disable-next-line no-console
audio::-webkit-media-controls-current-time-display {
	display: none !important;
}
// eslint-disable-next-line no-console
audio::-webkit-media-controls-time-remaining-display {
	display: none !important;
}
// eslint-disable-next-line no-console
audio::-moz-media-controls-time-remaining-display {
	display: none !important;
}

figure {
	background: #ddd;
	height: 200px;
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	position: absolute;
	left: 150px;
	z-index: 4;
}

figure button[name='play'] {
	width: 50px;
	height: 50px;
	background: #0085ff;
	border: none;
	border-radius: 100%;
	margin: auto;
	cursor: pointer;
}
figure button[name='play']:focus {
	outline: 0;
	border: 1px solid hsl(210, 58%, 69%);
	box-shadow: 0 0 0 3px hsla(210, 76%, 57%, 0.5);
}

figure button[name='play']::after {
	content: '';
	display: inline-block;
	position: relative;
	top: 1px;
	left: 3px;
	border-style: solid;
	border-width: 10px 0 10px 20px;
	border-color: transparent transparent transparent white;
}

.hideAudioControls {
	display: none;
}

.playButton {
	background: transparent;
	top: 50%;
	transform: translateY(-15%);
	position: absolute;
}

.muteButton {
	background-color: white;
	width: 30px;
	height: 25px;
	bottom: 8px;
	right: 8px;
	border: none;
	cursor: pointer;
	z-index: 1;
	padding: 0;
	opacity: 0;
	position: absolute;
}