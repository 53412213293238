@import '../../styles/colors';
@import '../../styles/placeholders';

.container {
	position: relative;
}

.input {
	background-color: grey;
	width: 25px;
	height: 25px;
	border-radius: 5px;
	border: 1px solid $input-box-border;
	filter: drop-shadow(0px 2px 5px $input-box-border);
	-webkit-filter: none;
	@extend %global-transition;
}

.picker {
	position: absolute;
	z-index: 2;
	top: 35px;
	left: -8px;
	padding-bottom: 75px; // setting this allows the delete and save buttons to appear

	div {
		width: 100%;
	}
}

/* These styles aren't used anywhere
.fixedPicker > div {
	width: 100%;
	border-radius: 5px !important;
	border: 1px solid $input-box-border !important;
	filter: drop-shadow(0px 2px 5px $input-box-border);
	-webkit-filter: none;
	box-shadow: none !important;
}

.fixedPicker > div > div:nth-of-type(1) {
	display: none;
}

.fixedPicker > div > div:nth-of-type(2) {
	display: none;
}

*/
