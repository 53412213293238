@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/media.scss';
@import '../../styles/placeholders.scss';

.container {
	display: flex;
	height: 100vh;
	background-image: url('../../assets/images/LoginGrafik.png');
	background-position: center;
	background-size: cover;

	button {
		min-width: 340px;
		font-size: 20px;
	}
}

.left {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.textWrapper {
	position: absolute;
	bottom: 13%;
}

.title {
	font-size: 60px;
	color: white;
	font-weight: 400;
}

.subtitle {
	font-size: 22px;
	color: white;
	margin-top: 5%;
	font-weight: 300;
	line-height: 26px;
}

.buttonWrapper {
	margin-top: 13%;
}

.logo {
	width: 165px;
	height: 153px;
}

.form {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.inputWrapper {
	margin-top: 25px;
	min-width: 340px;
}

.checkboxWrapper {
	position: relative;
	margin-top: 30px;
	max-width: 340px;
	display: flex;
	align-items: center;
	margin-left: 5px;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $light-color;
	font-weight: 300;
	margin-left: 8px;
	line-height: 18px;
}

.checkboxLink {
	color: $light-color;
	@extend %global-transition;

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;
	}
}

.error {
	position: absolute;
	top: 38px;
	left: 5px;
	font-size: 12px;
	color: $error-color;
	visibility: hidden;
	width: 100%;
	text-align: center;
}

.show {
	visibility: initial;
}

.submitWrapper {
	margin-top: 30px;
}

.warning {
	font-size: 15px;
	color: $error-color;
	margin-top: 20px;
}

.success {
	color: $primary-color;
}

.link {
	font-size: 15px;
	text-decoration: none;
	color: $text-color;
	font-weight: 300;
	@extend %global-transition;
	margin-top: 20px;

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;
	}
}

.mobileLink {
	display: none;
}

@include tablet-down {
	.container {
		flex-direction: column;
		background-position: right 8% bottom 15%;
	}

	.left {
		display: none;
	}

	.inputWrapper {
		&:first-of-type {
			margin-top: 5px;
		}
	}

	.checkboxWrapper {
		position: relative;
		margin-top: 20px;
		margin-left: 20px;
		display: flex;
	}

	.submitWrapper {
		margin-top: 0px;
	}

	.mobileLink {
		display: initial;
	}
}
