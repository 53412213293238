@import '../../styles/colors';
@import '../../styles/z_indexes';
@import '../../styles/placeholders';
@import '../../styles/shared_variables';

.canvas {
  width: calc(100% - #{$global-sidebar-width-desktop});
  overflow: hidden;
  position: relative;
  z-index: $z-canvas;

  // &.isCreating {
  // 	div[tabindex='0'] {
  // 		cursor: copy;
  // 	}
  // }
}

.projectTitle {
  position: absolute;
  z-index: $z-title;
  color: $text-color;
  font-size: 30px;
  top: 40px;
  left: 50px;
  cursor: pointer;
  @extend %global-transition;

  &:hover,
  &:focus,
  &:active {
    color: $primary-color;

    svg {
      path {
        @extend %global-transition;
        fill: $primary-color;
      }
    }
  }
}

.ico {
  width: 14px;
  height: 22px;
  margin-right: 20px;
}

.plusButton {
  position: absolute;
  z-index: $z-title;
  bottom: 30px;
  right: 390px;
  cursor: pointer;

  &:hover,
  &:focus {
    path {
      @extend %global-transition;
      &:first-of-type {
        fill: darken($primary-color, 10%);
      }
    }
  }
}

.active {
  path {
    &:first-of-type {
      fill: darken($primary-color, 15%);
    }
  }
}

.scene {
  // position: absolute;
  width: 356px;
  height: 206px;
  border: 3px solid transparent;

  div:first-of-type > div:nth-of-type(2) {
    display: none;
  }
}

.copyButton {
  display: none;
  position: absolute;
  top: -12px;
  right: 12px;
  z-index: 99;
  background: $canvas-copy-bg-black;
  color: white;
  height: 20px;
  width: 20px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bulkCopyButton {
  position: absolute;
  z-index: $z-title;
  bottom: 30px;
  right: 80px;
  cursor: pointer;
  background: $canvas-copy-bg-black;
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.copyIcon {
  width: 10px;
  height: 10px;
}
.bulkCopyIcon {
  margin-top: 4px;
  margin-left: 9px;
  width: 22px;
  height: 22px;
}

.deleteButton {
  display: none;
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 99;
  background: $canvas-delete-bg-red;
  color: white;
  height: 20px;
  width: 20px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bulkDeleteButton {
  position: absolute;
  z-index: $z-title;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  background: $canvas-delete-bg-red;
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.bulkDeleteX {
  position: relative;
  top: 3px;
  left: 10px;
  font-size: 26px;
  font-weight: bold;
}

.selected {
  border-color: $primary-color;
  // position: relative; // this pulls the border to the forefront
  .copyButton {
    display: flex;
  }
  .deleteButton {
    display: flex;
  }
}


.publishBtn {
  position: absolute;
  z-index: $z-title;
  top: 40px;
  right: 550px;
}

.previewBtnEnabled {
  position: absolute;
  z-index: $z-title;
  top: 40px;
  right: 470px;
  width: 57px;
  height: 57px;
  cursor: pointer;

  &:hover,
  &:focus {
    circle {
      @extend %global-transition;
      &:first-of-type {
        fill: darken($primary-color, 10%);
      }
    }
  }
}
.previewBtnDisabled {
  position: absolute;
  z-index: $z-title;
  top: 40px;
  right: 470px;
  width: 57px;
  height: 57px;
  cursor: not-allowed;
  circle {
    fill: $light-color;
  }
}

.playButton {
  position: absolute;
  z-index: $z-title;
  top: 40px;
  right: 390px;
  width: 57px;
  height: 57px;
  cursor: pointer;

  &:hover,
  &:focus {
    path {
      @extend %global-transition;
      &:first-of-type {
        fill: darken($primary-color, 10%);
      }
    }
  }
}

.firstSceneBadge {
  position: absolute;
  z-index: $z-title;
  bottom: 80px;
  right: 420px;
  width: 420px;
}

.arrowBadge {
  position: absolute;
  z-index: $z-title;
  bottom: 80px;
  right: 380px;
  width: 100px;
}

.sceneClickBadge {
  position: absolute;
  z-index: $z-title;
  bottom: 30px;
  right: 480px;
  width: 460px;
}
