@import '../../styles/colors';
@import '../../styles/placeholders';

.inputGroup {
	padding: 10px 0px;
	display: flex;
	align-items: center;
}

.label {
	font-size: 12px;
	color: $text-color;
}

.plusIco {
	cursor: pointer;
	margin-left: 19px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($primary-color, 10%);
		}
	}
}

.error {
	color: red;
	font-size: 14px;
	text-align: center;
	padding-bottom: 10px;
}
