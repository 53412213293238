@import '../../styles/colors';
@import '../../styles/placeholders';

.inputLabel {
	width: 105px;
	height: 70px;
	background-color: rgba(166, 167, 173, 0.2);
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	@extend %global-transition;

	&:hover,
	&:focus,
	&:active {
		background-color: rgba(166, 167, 173, 0.3);
		svg {
			path {
				@extend %global-transition;
				fill: $primary-color;
			}
		}
	}
}

.icon {
	width: 42px;
}

.span {
	font-size: 12px;
	line-height: 14px;
	color: black;
	text-transform: uppercase;
	margin-top: 6px;
}

.imagePreview {
	width: 105px;
	height: 70px;
	border-radius: 10px;
	object-fit: cover;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $primary-color;
	}
}
