@import '../../styles/colors';
@import '../../styles/placeholders';

.template {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 30px;
	background: grey;
	color: white;
	background-image: url('../../assets/images/TemplateBg.png');
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;
}

.backButton {
	position: absolute;
	top: 12px;
	left: 8px;
	height: 14px;
	cursor: pointer;
	z-index: 1;

	path {
		fill: inherit;
	}

	&:hover,
	&:focus,
	&:active {
		path {
			@extend %global-transition;
		}
	}
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 14px;
	color: $light-color;
	position: relative;
	top: -40px;
}

.templateRow {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.question {
	font-size: 18px;
	color: $text-color;
	width: 100%;
	margin-bottom: 6px;
	font-weight: bold;
	line-height: 22px;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.buttonWrapper {
	margin-top: 5px;
	margin-right: 10px;
	width: 90px;
	cursor: pointer;

	&:last-of-type {
		margin-right: 0px;
	}
}

.buttonWrapperSmall {
	margin-right: 5px;
	width: 78px;
}

.answerColumn {
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 3px;
	position: relative;

	button {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		padding: 5px;
		min-height: 20px;
	}
}

.priceBubble {
	position: absolute;
	top: 22px;
	left: 56px;
	display: block;
	width: 31px;
	height: 31px;
	border-radius: 50%;
	background-color: $text-color;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.priceBubbleSmall {
	width: 26px;
	height: 26px;
	left: 49px;
	top: 18px;
}

.priceLabel {
	font-size: 5px;
	display: block;
	line-height: 6px;
}

.priceLabelSmaall {
	font-size: 4px;
}

.price {
	font-size: 7px;
	font-weight: bold;
}

.priceSmall {
	font-size: 6px;
}

.imageTitle {
	display: block;
	color: $text-color;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 4px;
	margin-top: 4px;
}

.labels {
	display: flex;
	flex-direction: column;
	max-width: 100px;
}

.richText {
	flex: 1;
	font-size: 7px;
	color: $text-color;
	padding: 0px 9px;
	line-height: 9px;
	margin-bottom: 4px;

	strong {
		font-weight: bold;
	}
	em {
		font-style: italic;
	}
	ul {
		padding-left: 0;

		li {
			padding-left: 5px;
			list-style-type: disc;
			list-style-position: inside;
		}

		li:not(.ql-direction-rtl) {
			padding-left: 5px;
		}

		li::marker {
			margin: 0px 5px 0px -1px;
		}
	}

	ul > li:before {
		content: none;
	}

	ol {
		padding-left: 0;

		li {
			padding-left: 5px;
			list-style-type: decimal;
			list-style-position: inside;
		}
		li:not(.ql-direction-rtl) {
			padding-left: 5px;
		}
	}
}

.customHtml {
	height: 100px;
	width: 100%;
	overflow: hidden;
	margin-bottom: 10px;
	// border: 1px solid black;
	// background-color: whitesmoke;
	// color: blue;
	.defaultHtmlImg {
		max-width: 100%;
	}
}
