@import '../../styles/colors';
@import '../../styles/z_indexes';
@import '../../styles/shared_variables';
@import '../../styles/placeholders';

.checkboxWrapper {
	display: flex;
	align-items: center;
	margin-top: 20px;
	padding: 10px 40px;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}
