@import '../../styles/colors';
@import '../../styles/placeholders';

.container {
	width: 100%;
	padding: 50px;
}

.title {
	color: $text-color;
	font-size: 30px;
	margin-bottom: 40px;
}

.table {
	color: $text-color;
	width: 100%;
	text-align: left;
	filter: drop-shadow(0px 2px 5px $input-box-border);
	-webkit-filter: none;
	margin-bottom: 30px;
}

.tr {
	border: 1px solid $text-color;
}

.th {
	font-weight: bold;
	font-size: 15px;
	padding: 15px;
	text-align: center;
}

.td {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 15px;
	padding: 15px;
	text-align: center;
	max-width: 250px;
	&:hover {
		word-wrap: break-word;
		white-space: normal;
		height: fit-content;
	}
	&:last-child {
		color: $primary-color;
	}
}

.textLeft {
	text-align: left;
}

.popup {
	background-color: $light-background-color;
	width: 100%;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 0 0 4px $light-color;

	ol {
		margin-top: 20px;
		list-style: inside;
		text-align: left;
		list-style-type: decimal;
		li {
			margin-bottom: 5px;
		}
	}
	h1 {
		font-size: 26px;
		text-align: center;
		text-transform: uppercase;
	}

	//.popupText {
	//	text-align: left;
	//}
}

.divContainer {
	display: flex;
	justify-content: space-between;
}

.downloadButton {
	width: 20%;
	align-self: flex-end;
	height: 40px;
	margin-bottom: 2%;
}

.datePickerContainer {
	display: flow-root;
	position: relative;
	bottom: 30px;
}

.datePickerLabel {
	text-align: center;
	margin-bottom: 10px;
	font-size: 16px;
	margin-right: 40px;
}

.clearFilter {
	margin-top: 8px;
	margin-left: 5px;
}

.pickerAndClearButtonContainer {
	display: flex;
}

.minusIco {
	position: relative;
	top: 8px;
	margin-left: 5px;
	width: 30px;
	cursor: pointer;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($error-color, 10%);
		}
	}
}

.navigation {
	display: flex;
	list-style-position: inside;
	justify-content: center;
}

.navigationButton {
	border: 1px solid #0085ff;
	margin: 5px 10px;
}

.numberOfPages {
	margin: auto 0;
	color: $sessions-black-title;
	font-weight: 600;
	font-size: 15px;
}
