@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/z_indexes';

.footerBox {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 30px;

	hr:first-of-type {
		display: initial;
	}
}

.buttonBox {
	position: fixed;
	top: 91vh;
	height: 100px;
	width: 240px;
	padding: 5px;
	background-color: $light-background-color;
	z-index: $z-stickyButtons; // = 20; Answer.js has $z-navbar = 10
}

.buttonRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0px;

	button {
		padding: 10px 33px;
		font-size: 15px;
	}
}

.link {
	font-size: 15px;
	cursor: pointer;
	color: $error-color;
	@extend %global-transition;

	&:hover,
	&:focus {
		color: darken($error-color, 10%);
	}
}

.warning {
	font-size: 14px;
	text-align: center;
}
