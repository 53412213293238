@import '../../styles/colors';

.hr {
	border: 1px solid $background-color;
	margin: 20px 0px;

	&:first-of-type {
		display: none;
	}
}

.box {
	margin-top: 20px;
	padding-left: 40px;
	padding-right: 40px;
}
