@import '../../styles/colors';
@import '../../styles/placeholders';

.container {
	margin: 50px auto 20px;
	padding: 10px;
}

.goBack {
	color: $text-color;
	font-size: 20px;
	width: 100px;
	cursor: pointer;
	@extend %global-transition;

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;

		svg {
			path {
				@extend %global-transition;
				fill: $primary-color;
			}
		}
	}
}

.ico {
	margin-top: -2px;
	width: 10px;
	height: 15px;
	margin-right: 7px;
}

.dateDropDown {
	// width: 400px;
	font-size: 15px;
	text-align: end;
}
.presetBtn {
	background-color: $secondary-color;
	color: $text-color;
	padding: 2px 4px;
	margin: 0 2px 0 4px;
	border: 1px solid $grey;
	border-radius: 4px;
	height: 32px;
	font-size: 12px;
	cursor: pointer;
	&:hover,
	&:focus,
	&:active {
		background-color: darken($secondary-color, 10%);
	}
}

.header {
	width: 100%;
	background-color: $secondary-color;
	border-radius: 20px;
	// padding: 20px;  this gets over-ridden by bootstrap
}
.projectTitle {
	color: $sessions-black-title;
	font-size: 30px;
	font-weight: 600;
}
.projectDescription {
	font-size: 15px;
	margin-top: -5px;
}
.projectDate {
	font-size: 12px;
	margin-top: 5px;
	font-weight: 600;
}
.projectNumber {
	text-align: end;
	font-size: 12px;
	margin-top: 5px;
	font-weight: 600;
}

.numSessions {
	background-color: $sessions-light-purple;
	border: 1px solid $sessions-dark-purple;
	border-radius: 20px;
	color: $sessions-dark-purple;
	padding: 15px;
	height: 100%;
}

.started {
	background-color: $sessions-light-orange;
	border: 1px solid $sessions-dark-orange;
	border-radius: 20px;
	color: $sessions-dark-orange;
	padding: 15px;
}

.completed {
	background-color: $sessions-light-green;
	border: 1px solid $sessions-dark-green;
	border-radius: 20px;
	color: $sessions-dark-green;
	padding: 15px;
	cursor: pointer;
}

.cardTitle {
	text-transform: uppercase;
	text-align: end;
	font-size: 15px;
	font-weight: 600;
}

.mainMetric {
	margin: 20px 0 0 15px;
	font-size: 50px;
	font-weight: 300;
}

.secondMetric {
	margin: 5px 0 0 15px;
	font-size: 20px;
	font-weight: 600;
}

.sessions {
	width: 100%;
	background-color: $secondary-color;
	border-radius: 20px;
}

.sessionsTitle {
	color: $sessions-black-title;
	font-size: 26px;
	font-weight: 600;
	margin: 20px;
}

.table {
	width: 100%;
}

.th {
	color: $sessions-black-title;
	font-weight: 600;
	font-size: 15px;
	padding: 10px;
	text-align: center;
}

.sort {
	cursor: pointer;
}

.sortDown {
	margin: -2px 0 0 3px;
	width: 10px;
	height: 15px;
}
.sortUp {
	margin: -5px 0 0 3px;
	width: 10px;
	height: 15px;
	transform: rotate(180deg);
}

.td {
	font-size: 14px;
	padding: 8px;
	text-align: center;
	// width: 100%;  // this adds unwanted width to first two columns
}

.urlCell {
	display: block;
	// position: relative;  // this makes the text adjust upwards instead of downwards
	white-space: nowrap;
	max-width: 10vw;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover {
		word-wrap: break-word;
		white-space: normal;
		height: fit-content;
	}
	@media only screen and (min-width: 768px) {
		max-width: 20vw;
	}
	@media only screen and (min-width: 992px) {
		max-width: 30vw;
	}
	@media only screen and (min-width: 1200px) {
		max-width: 40vw;
	}
	@media only screen and (min-width: 1700px) {
		max-width: 35vw;
	}
	@media only screen and (min-width: 2000px) {
		max-width: 30vw;
	}
	@media only screen and (min-width: 2300px) {
		max-width: 25vw;
	}
	@media only screen and (min-width: 2700px) {
		max-width: 22vw;
	}
}

.navigation {
	display: flex;
	list-style-position: inside;
	justify-content: center;
}

.navigationButton {
	border: 1px solid #0085ff;
	margin: 5px 10px;
}

.numberOfPages {
	margin: auto 0;
	color: $sessions-black-title;
	font-weight: 600;
	font-size: 15px;
}

.popup {
	background-color: $light-background-color;
	width: 100%;
	padding: 30px;
	border-radius: 20px;
	box-shadow: 0 0 4px $light-color;

	ol {
		margin-top: 20px;
		list-style: inside;
		text-align: left;
		list-style-type: decimal;
		li {
			margin-bottom: 5px;
		}
	}
	h1 {
		font-size: 26px;
		text-align: center;
		text-transform: uppercase;
	}
}

.trends {
	background-color: $secondary-color;
	border-radius: 20px;
	padding: 20px;
}
.trendsTitle {
	color: $sessions-black-title;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 30px;
}
.trendsSubtitle {
	margin-bottom: 10px;
	color: $sessions-black-title;
	font-size: 14px;
	font-weight: 600;
}
.trendsGraph {
	// background-color: $background-color;
	// font-size: 15px;
	// font-weight: 400;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	// margin: 20px 0;
	height: 130px;
	width: 100%;
}

.callToAction {
	background-color: $sessions-dark-purple;
	background-image: url(../../assets/images/PieDiagram.png);
	background-position: right top;
	background-repeat: no-repeat;
	background-size: 150px;
	background-blend-mode: multiply;
	border-radius: 20px;
	color: $secondary-color;
	white-space: pre-line;
	line-height: 1.3;
	height: 400px;
}
.ctaTitle {
	font-size: 22px;
	font-weight: 600;
	margin-top: 10px;
}
.ctaSubtitle {
	margin-top: 15px;
	font-size: 14px;
	font-weight: 500;
}
