$mobile-width: 544px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-width: 1200px;
$extra-large-width: 1440px;

//mobile-first

@mixin tablet-up() {
	@media (min-width: #{$tablet-width}) {
		@content;
	}
}

@mixin desktop-up() {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

@mixin large-up() {
	@media (min-width: #{$large-width}) {
		@content;
	}
}

@mixin extra-large-up() {
	@media (min-width: #{$extra-large-width}) {
		@content;
	}
}

//desktop-first

@mixin large-down() {
	@media (max-width: #{$extra-large-width - 1px}) {
		@content;
	}
}

@mixin desktop-down() {
	@media (max-width: #{$large-width - 1px}) {
		@content;
	}
}

@mixin tablet-down() {
	@media (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin mobile-down() {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

//mobile only
@mixin mobile() {
	@media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

//tablet only
@mixin tablet() {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

//desktop only
@mixin desktop() {
	@media (min-width: #{$desktop-width}) and (max-width: #{$large-width - 1px}) {
		@content;
	}
}

//large only
@mixin large() {
	@media (min-width: #{$large-width}) and (max-width: #{extra-large-width - 1px}) {
		@content;
	}
}
