@import '../../styles/colors';
@import '../../styles/placeholders';

.template {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	width: 100%;
	padding: 30px;
	background: grey;
	color: white;
	background-image: url('../../assets/images/TemplateBg.png');
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;

	button {
		padding: 5px 10px;
		min-height: 15px;
		min-width: 100px;
	}
}

.backButton {
	position: absolute;
	top: 12px;
	left: 8px;
	height: 14px;
	cursor: pointer;
	z-index: 1;

	path {
		fill: inherit;
	}

	&:hover,
	&:focus,
	&:active {
		path {
			@extend %global-transition;
		}
	}
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 14px;
	color: $light-color;
	position: relative;
	top: -40px;
}

.templateRow {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.question {
	font-weight: 500;
	font-size: 18px;
	color: $text-color;
	width: 100%;
	font-weight: bold;
	line-height: 22px;
}

.description {
	font-weight: 500;
	font-size: 12px;
	color: $text-color;
	width: 100%;
	line-height: 22px;
}

.result {
	color: $text-color;
	font-weight: bold;
	width: 100%;
	line-height: 22px;
	margin-bottom: 5px;
}

.inputRow {
	display: flex;
	margin-top: -3px;
}

.inputWrapper {
	width: 100%;
}
.jsonResolution {
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.buttonWrapper {
	margin-top: 12px;
	width: 100%;
	button {
		width: 100%;
	}
}

.secondSceneButtonWrapper {
	width: 50%;
	margin: 0px auto;

	button {
		width: 100%;
	}
}

.flexCol {
	flex-direction: column;
	justify-content: center;
}

.error {
	width: 100%;
	font-size: 7px;
	color: $error-color;
	visibility: hidden;
	text-align: center;
	position: absolute;
	bottom: -6px;
}

.show {
	visibility: initial;
}

.warning {
	display: block;
	position: absolute;
	z-index: 2;
	height: 100%;
	width: 100%;
	padding: 30px;
	background: grey;
	color: $text-color;
	font-size: 9px;
	font-weight: bold;
	background-image: url('../../assets/images/TemplateBg.png');
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;
	display: flex;
	align-items: center;
}

.source {
	font-size: 6px;
	font-style: italic;
	color: #4d4f5c;
	margin-top: 5px;
}

.suggestions {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
	::-webkit-scrollbar-track {
		-webkit-box-shadow: none !important;
		background-color: transparent;
	}
	::-webkit-scrollbar {
		width: 3px !important;
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
	font-size: 10px;
	border-radius: 2px 2px 4px 4px;
	border-top-width: 0;
	box-shadow: 0 0 3px #999;
	list-style: none;
	margin-top: 0;
	max-height: 48px;
	overflow-y: auto;
	padding-left: 0;
	width: calc(135px + 1rem);
}

.suggestions ul {
	//max-height: 100px;
	max-width: 200px;
	margin: 0;
	padding: 0;
	overflow: auto;
	/* this will hide the scrollbar in mozilla based browsers */
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
	/* this will hide the scrollbar in internet explorers */
	-ms-overflow-style: none;

	li {
		height: 22px;
		background-color: #fff;
		border-bottom: 1px dotted $light-grey;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $text-color;
	}
}

.suggestion-active,
.suggestions li:hover {
	background-color: #0085ff;
	color: #f9f9f9;
	cursor: pointer;
	font-weight: 700;
}

.richText {
	flex: 1;
	font-size: 7px;
	color: $text-color;
	padding: 5px 9px 0px 0px;
	line-height: 9px;
	margin-bottom: 4px;

	strong {
		font-weight: bold;
	}
	em {
		font-style: italic;
	}
	ul {
		padding-left: 0;

		li {
			padding-left: 5px;
			list-style-type: disc;
			list-style-position: inside;
		}

		li:not(.ql-direction-rtl) {
			padding-left: 5px;
		}

		li::marker {
			margin: 0px 5px 0px -1px;
		}
	}

	ul > li:before {
		content: none;
	}

	ol {
		padding-left: 0;

		li {
			padding-left: 5px;
			list-style-type: decimal;
			list-style-position: inside;
		}
		li:not(.ql-direction-rtl) {
			padding-left: 5px;
		}
	}
}
