@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/z_indexes';

.selectLang {
  width: 265px;
  margin: 15px 38px 0px;
}

.changeLangLabel {
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0;
  color: $text-color;
}
