@import '../../styles/colors';
@import '../../styles/placeholders';

.container {
	padding: 10px;
	background-color: $background-color;
	border-radius: 10px;
}

.form {
	font-size: 14px;
	color: $text-color;
}

.fieldNameErrMsg {
	margin: 0 0 5px 84px;
	font-size: 10px;
	white-space: pre;
}
