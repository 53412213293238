@import '../../styles/colors';
@import '../../styles/placeholders';

.box {
	padding: 0px 0px 10px;
	display: flex;
	flex-direction: column;
}

.owner {
	display: block;
	font-size: 12px;
	font-weight: 600;
	margin-top: 5px;
	color: $text-color;
}
.name {
	display: block;
	font-size: 12px;
	margin: 5px 0 5px 5px;
	color: $text-color;
}

.icon {
	cursor: pointer;
	margin-left: 15px;
	margin-bottom: 1px;
	path {
		@extend %global-transition;
	}

	&:hover,
	&:active,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}

.instructions {
	font-size: 10px;
	font-weight: 600;
	margin: 5px 0 5px 5px;
}
.form {
	max-width: 230px;
}
