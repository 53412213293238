@import '../../styles/colors';
@import '../../styles/placeholders';

.inputGroupDescription {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
}

.box {
	padding: 0px 0px 10px;
	display: flex;
	flex-direction: column;
}

.icon {
	cursor: pointer;
	margin-left: 15px;
	margin-bottom: 1px;
	path {
		@extend %global-transition;
	}

	&:hover,
	&:active,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}

.minusIcon {
	cursor: pointer;
	margin-left: 5px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($primary-color, 10%);
		}
	}
}

.plusIcon {
	cursor: pointer;
	margin-left: 19px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($primary-color, 10%);
		}
	}
}

.addFieldInputGroup {
	padding: 10px 0px;
	display: flex;
	align-items: center;
}
.addFieldLabel {
	font-size: 12px;
	color: $text-color;
}

.label {
	display: block;
	font-size: 12px;
	margin-bottom: 10px;
	margin-top: 15px;
	color: $text-color;
}

.inputGroup {
	padding: 15px 0px;
	display: flex;
	align-items: center;
	input[type='checkbox'] {
		margin-right: 10px;
	}
}

.inputLabel {
	font-size: 14px;
}

.inputGroupBackButton {
	padding: 15px 40px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}

.lightLabel {
	margin-bottom: 8px;
	color: $light-color;
}
