$base-blue-color: #0085ff;
$base-white-color: #ffffff;
$base-orange-color: #fa991c;
$base-grey-color: #4d4f5c;
$base-light-grey-color: #a6a7ad;

$primary-color: $base-blue-color;
$secondary-color: $base-white-color;
$accent-color: $base-orange-color;
$text-color: $base-grey-color;
$light-color: $base-light-grey-color;
$grey: #bbb;
$light-grey: #eee;

$background-color: #f4f4f4;
$light-background-color: #fbfbfb;
$error-color: #ff0000;

$input-box-border: #ededed;
$canvas-copy-bg-black: #585968;
$canvas-delete-bg-red: #f46e69;
$sessions-dark-green: #84e0be;
$sessions-light-green: #ebfff8;
$sessions-dark-purple: #ababfd;
$sessions-light-purple: #efefff;
$sessions-dark-orange: #ffbc6e;
$sessions-light-orange: #fff6eb;
$sessions-black-title: #2e4765;

//$shadow-color: #e0e2e4;
