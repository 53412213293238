@import '../../styles/shared_variables';
@import '../../styles/colors';

.dashboard {
	height: 100vh;
}

.container {
	background-color: $background-color;
	color: grey;
	height: calc(100vh - #{$global-navbar-height-desktop});
	font-size: 30px;
	overflow: auto;
	display: flex;
	position: relative;
}

.noScroll {
	overflow-y: hidden;
}
