@import '../../styles/colors';

.box {
	padding: 0px 0px 10px;
	display: flex;
	flex-direction: column;
}

.label {
	display: block;
	font-size: 12px;
	margin-bottom: 10px;
	margin-top: 15px;
	color: $text-color;
}

.inputGroup {
	padding: 15px 0px;
	display: flex;
	align-items: center;
	input[type='checkbox'] { margin-right: 10px }
}

.inputLabel {
	font-size: 14px;
}

.inputGroupBackButton {
	padding: 15px 40px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}

.lightLabel {
	margin-bottom: 8px;
	color: $light-color;
}

.otherInput {
	padding: 3px;
	outline: none;
	font-size: 14px;
	color: grey;
	background-color: transparent;
	border: 1px solid #a6a7ad;
	border-radius: 4px;
	width: 130px;
}