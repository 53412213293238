@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/media.scss';

.container {
	display: flex;
	height: 100vh;
	background-image: url('../../assets/images/LoginGrafik.png');
	background-position: center;
	background-size: cover;

	button {
		min-width: 340px;
		font-size: 20px;
	}
}

.left {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.textWrapper {
	position: absolute;
	bottom: 13%;
}

.title {
	font-size: 50px;
	color: white;
	font-weight: 400;
}

.subtitle {
	font-size: 22px;
	color: white;
	margin-top: 5%;
	font-weight: 300;
	line-height: 26px;
	margin-bottom: 115px;
}

.buttonWrapper {
	margin-top: 13%;
}

.logo {
	width: 165px;
	height: 153px;
}

.form {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.inputWrapper {
	margin-top: 25px;
	min-width: 340px;
}

.error {
	position: absolute;
	top: 23px;
	left: 5px;
	font-size: 12px;
	color: $error-color;
	visibility: hidden;
}

.submitWrapper {
	margin-top: 30px;
}

.link {
	font-size: 15px;
	text-decoration: none;
	color: $text-color;
	font-weight: 300;
	@extend %global-transition;
	margin-top: 25px;

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;
	}
}

.warning {
	font-size: 15px;
	color: $error-color;
	margin-top: 20px;
}

.success {
	color: $primary-color;
}

@include tablet-down {
	.container {
		flex-direction: column;
		background-position: right 25% bottom 15%;
	}

	.left {
		display: none;
	}
}
