.progressBar {
	position: relative;
	font-size: 12px;
	height: 6px;
	width: calc(100% - 20px);
	margin-left: 10px;
	background: #a6a7ad 0 0 no-repeat padding-box;
	border-radius: 35px;
	opacity: 1;
}

.filler {
	background: #0085ff 0% 0% no-repeat padding-box;
	border-radius: 35px;
	opacity: 1;
	height: 100%;
	transition: width 0.2s ease-in;
}
